<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { GetDataMethods, PostDataMethods, notificationMethods } from "@/state/helpers";
export default {
    components: {
        Layout,
        PageHeader,
        Multiselect,
    },
    data() {

        return {
            title: "Edite User",
            items: [
                {
                    text: "Wesal",
                },

            ],
            userData: null,
            FormData: {
                username: "name",
                phone: "0988888888",
                password: "pass",
                role: "role",
                deposit: 0,
                credit: 0,
                user_id: 0,
                points: 0,
            },
            options: ["user", "manager"],
        };

    },
    props: ["id"],

    mounted() {
        // Set the initial number of items
        this.GetUserData();
    },

    methods: {
        ...GetDataMethods,
        ...PostDataMethods,
        ...notificationMethods,
        submitForm() {
            this.putData({ key: "update_user/" + this.id, data: this.FormData }).then(response => {
                if (response.status == 422)
                    this.makeToast("danger ", "Fial", response.data.message);
                else if (response.status == 500)
                    this.makeToast("danger ", "Fial", response.data.message);
                else
                    this.makeToast("success ", "Success", response.data.message);

            })
        },
        GetUserData() {
            this.getData("get_user_by_id/" + this.id).then((res) => {
                this.userData = res.data.data.user
                this.FormData.username = this.userData.username;
                this.FormData.points = this.userData.points;
                this.FormData.phone = this.userData.phone;
                this.FormData.password = this.userData.password;
                this.FormData.role = this.userData.role;
                this.FormData.deposit = this.userData.deposit;
                this.FormData.credit = this.userData.credit;
                this.FormData.user_id = this.userData.user_id;
            });
        },

        makeToast(variant = null, body = null, title = null) {
            this.$bvToast.toast(title, {
                title: `${body}`,
                variant: variant,
                solid: true,
            });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="conteanir" color="#5664d2">
                            <div class="row-main" title="Basic Info">
                                <div class="tab-pane" id="basic-info">
                                    <h4 class="card-title">User Information</h4>
                                    <p class="card-title-desc">Fill all information below</p>
                                    <form @submit.prevent="submitForm">
                                        <div class="form-group">

                                            <label for="productname">User ID</label>
                                            <input disabled v-model="userData.id" id="productname" name="productname"
                                                type="text" class="form-control mb-2" />


                                            <div class="from-groupm">
                                                <label for="productname">User Name</label>
                                                <input v-model="FormData.username" id="productname" name="productname"
                                                    type="text" class="form-control mb-2" />
                                            </div>

                                            <div class="form-group">
                                                <label for="manufacturername">Phone</label>
                                                <input v-model="FormData.phone" id="manufacturername"
                                                    name="manufacturername" type="text" class="form-control mb-2" />
                                            </div>

                                            <div class="form-group">
                                                <label for="manufacturername">Points</label>
                                                <input v-model="FormData.points" id="manufacturername"
                                                    name="manufacturername" type="number" min="0"
                                                    class="form-control mb-2" />
                                            </div>

                                            <div class="form-group">
                                                <label for="manufacturername">Deposit</label>
                                                <input v-model="FormData.deposit" id="manufacturername"
                                                    name="manufacturername" type="text" min="0"
                                                    class="form-control mb-2" />
                                            </div>

                                            <div class="form-group">
                                                <label for="manufacturername">Credit</label>
                                                <input v-model="FormData.credit" id="manufacturername"
                                                    name="manufacturername" type="text" min="0"
                                                    class="form-control mb-2" />
                                            </div>

                                            <div class="form-group">
                                                <label for="manufacturerbrand">New Password</label>
                                                <input v-model="FormData.password" id="manufacturerbrand"
                                                    name="manufacturerbrand" type="text" class="form-control mb-2" />
                                            </div>

                                            <div class="form-group">
                                                <label class="control-label">Roles</label>
                                                <multiselect v-model="FormData.role" :options="options">
                                                </multiselect>
                                            </div>

                                            <div class="form-group">
                                                <label for="manufacturerbrand">User ID</label>
                                                <input v-model="FormData.user_id" type="number"
                                                    class="form-control mb-2" />
                                            </div>

                                        </div>

                                        <div class="row d-flex justify-content-end">
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <div class="text-md-right mt-4">
                                                        <button type="submit"
                                                            class="btn btn-primary mr-2 waves-effect waves-light">
                                                            Edit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
